<template>
  <div class="card-body">
    <loading v-if="cargando"/>
    <div class="row" v-if="inspeccion">
      <div class="form-group col-md-8">
        <h4>{{ inspeccion.nombre }}</h4>
      </div>
      <div class="form-group col-md-4">
        <label>Estado:</label>
        <select
          class="form-control"
          v-model="estadoForm.estado_documento"
          @change="save"
          :disabled="estadoForm.estado_documento != 1 || !flagGestionado"
        >
          <option
            v-for="estado in listaForm.estados_documento"
            :key="estado.numeracion"
            :value="estado.numeracion"
          >
            {{ estado.descripcion }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-12">
        <div id="accordion">
          <div
            class="card m-1 card-secondary"
            v-for="(seccion, index_seccion) in $parent.formato_inspeccion
              .secciones"
            :key="seccion.id"
          >
            <div class="card-header">
              <a
                data-toggle="collapse"
                :href="`#collapse_${seccion.id}`"
                aria-expanded="false"
                class="d-block w-100 collapsed"
                ><h4 class="card-title w-100">{{ seccion.nombre }}</h4></a
              >
            </div>
            <div
              :id="`collapse_${seccion.id}`"
              data-parent="#accordion"
              class="collapse"
            >
              <div class="card-body">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                  v-if="seccion.items_inspeccion.length > 0"
                >
                  <thead>
                    <tr>
                      <th style="width: 75px">Relevante</th>
                      <th>Item</th>
                      <th style="width: 200px">Observación - Valor</th>
                      <th style="width: 120px">
                        Cumple
                        <select
                          class="form-control form-control-sm p-0"
                          v-model="form_seccion[index_seccion]"
                          :class="
                            form_seccion[index_seccion] == true
                              ? 'is-valid'
                              : form_seccion[index_seccion] == false
                              ? 'is-invalid'
                              : ''
                          "
                          :disabled="estadoForm.estado_documento == 2"
                          @change="gestionarSeccion(index_seccion)"
                        >
                          <option :value="null">Sin revisar</option>
                          <option :value="true">Si</option>
                          <option :value="false">No</option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="form[index_seccion]">
                    <tr
                      v-for="(item, index_item) in seccion.items_inspeccion"
                      :key="item.id"
                    >
                      <td class="text-center">
                        <i
                          :class="
                            item.pivot.relevante == 1
                              ? 'fa fa-check text-success'
                              : 'fa fa-times-circle text-danger'
                          "
                        ></i>
                      </td>
                      <td>
                        {{ item.nombre }}
                      </td>
                      <td class="text-center">
                        <input
                          v-model="form[index_seccion][index_item].valor"
                          :type="
                            item.tipo_control == 1
                              ? 'checkbox'
                              : item.tipo_control == 2
                              ? 'text'
                              : item.tipo_control == 3
                              ? 'number'
                              : item.tipo_control == 4
                              ? 'date'
                              : 'hidden'
                          "
                          class="form-control form-control-sm p-0"
                          :maxlength="
                            item.tipo_control == 2 ? item.tamano_control : ''
                          "
                          :disabled="estadoForm.estado_documento == 2"
                        />
                      </td>
                      <td class="text-center">
                        <select
                          class="form-control form-control-sm p-0"
                          v-model="form[index_seccion][index_item].cumple"
                          :class="
                            form[index_seccion][index_item].cumple == true
                              ? 'is-valid'
                              : form[index_seccion][index_item].cumple == false
                              ? 'is-invalid'
                              : ''
                          "
                          :disabled="estadoForm.estado_documento == 2"
                          @change="validaGestion"
                        >
                          <option :value="null">Sin revisar</option>
                          <option :value="true">Si</option>
                          <option :value="false">No</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-warning col-12" v-else>
                  <h5>
                    <i class="icon fas fa-exclamation-triangle"></i> Atención!
                  </h5>
                  No se encontraron Items de Inspección para realizar.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-md-12" v-if="array_no_cumplidos.length > 0">
        <label>Incumplimientos Inspección</label>
        <table class="table table-sm table-hover">
          <thead class="thead-dark">
            <tr>
              <th style="width: 75px">Relevante</th>
              <th>Sección</th>
              <th>Item</th>
              <th>Valor</th>
              <th style="width: 75px">Cumple</th>
              <th style="width: 75px">Inspecciones anteriores</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="no_cumplido in array_no_cumplidos" :key="no_cumplido.id">
              <td class="text-center">
                <i
                  v-if="no_cumplido.relevante"
                  class="fa fa-check text-success"
                ></i>
                <i v-else class="fa fa-times-circle text-danger"></i>
              </td>
              <td>{{ no_cumplido.seccion }}</td>
              <td>{{ no_cumplido.item }}</td>
              <td>{{ no_cumplido.valor }}</td>
              <td class="text-center">
                <i
                  v-if="no_cumplido.cumple"
                  class="fa fa-check text-success"
                ></i>
                <i v-else class="fa fa-times-circle text-danger"></i>
              </td>
              <td class="text-center">
                <div
                  v-for="incumplido in no_cumplido.incumplidosAnteriores"
                  :key="incumplido.id"
                >
                  {{ incumplido.inspeccion_id }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-else>
      <div class="alert alert-warning col-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se encontraron Inspecciones para realizar.
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "../../../../components/Loading";
import axios from "axios";

export default {
  name: "TifInspeccionGestiona",

  components: {
    Loading,
  },

  data() {
    return {
      cargando: true,
      inspeccion_id: null,
      inspeccion: null,
      vehiculo_id: null,
      flagGestionado: true,
      array_no_cumplidos: [],
      arrayIncumplidos: [],
      form: [],
      form_seccion: [],
      listaForm: {
        estados_documento: [],
      },
      estadoForm: {
        id: null,
        estado_documento: null,
      },
      forms: {
        det_inspeccion: [],
        inspeccion: {
          id: null,
          fecha_fin: null,
          hora_fin: null,
          estado: null,
          estado_documento: null,
        },
        vehiculo: {
          id: null,
          estado: null,
        },
        autorizacion: null,
      },
    };
  },

  methods: {
    //si
    async getIndex() {
      this.cargando = true;
      await this.$parent.getIndex();
      this.inspeccion_id = this.$parent.inspeccion_id;
      this.estadoForm.id = this.$parent.inspeccion_id;
      this.estadoForm.estado_documento = this.$parent.estado_documento;
      this.inspeccion = this.$parent.formato_inspeccion;
      if (this.$parent.form.cabezote) {
        this.vehiculo_id = this.$parent.form.cabezote.id;
        await this.getIncumplidos();
      } else {
        this.array_no_cumplidos = [];
        await this.getForm();
      }
      this.cargando = false;
    },
    //si
    getIncumplidos() {
      axios
        .get("api/tif/inspecciones/itemsIncumplidos", {
          params: {
            inspeccion_id: this.inspeccion_id,
            vehiculo_id: this.vehiculo_id,
          },
        })
        .then((response) => {
          this.arrayIncumplidos = response.data;
          this.array_no_cumplidos = [];
          this.getForm();
        });
    },
    //si
    getForm() {
      let me = this;
      let array = [];
      me.array_no_cumplidos = [];
      this.$parent.formato_inspeccion.secciones.forEach(function (
        seccion,
        index_seccion
      ) {
        let array_seccion = [];
        seccion.items_inspeccion.forEach(function (item_inspeccion) {
          // Se valida la informacion de la inspeccion
          let det_inspeccion = me.buscaInspeccion(item_inspeccion.pivot.id);
          let array_item_inspeccion = {
            id: det_inspeccion.id,
            secciones_has_items_inspeccion_id: item_inspeccion.pivot.id,
            valor: det_inspeccion.valor,
            cumple: det_inspeccion.cumple,
            relevante: item_inspeccion.pivot.relevante,
          };
          array_seccion.push(array_item_inspeccion);
          // Se valida los item de inspección que no cumplieron
          if (det_inspeccion.cumple == false) {
            let arrayIncumplido = [];
            me.arrayIncumplidos.forEach((incumplido) => {
              if (incumplido.item_id == item_inspeccion.pivot.id) {
                arrayIncumplido.push(incumplido);
              }
            });
            me.array_no_cumplidos.push({
              seccion: seccion.nombre,
              item: item_inspeccion.nombre,
              id: item_inspeccion.pivot.id,
              valor: det_inspeccion.valor,
              cumple: det_inspeccion.cumple,
              relevante: item_inspeccion.pivot.relevante,
              incumplidosAnteriores: arrayIncumplido,
            });
          }
        });
        array.push(array_seccion);
      });
      me.form = array;
      this.validaGestion();
      this.$parent.getIndex();
    },
    //si
    buscaInspeccion(id) {
      let array = {
        id: null,
        valor: null,
        cumple: null, // [null (sin revisar) | true (cumple) | false (no cumple)] Aqui se establece el valor por defecto inicial del estado del cumplimiento del item de inspeccion
      };
      this.$parent.detalles_inspecciones.forEach(function (value, index) {
        if (id == value.secciones_has_items_inspeccion_id) {
          let cumple = null;
          switch (value.cumple) {
            case 0:
              cumple = false;
              break;

            case 1:
              cumple = true;
              break;

            default:
              cumple = null;
              break;
          }
          array = {
            id: value.id,
            valor: value.valor,
            cumple,
          };
        }
      });
      return array;
    },
    //si
    gestionarSeccion(index_seccion) {
      this.form[index_seccion].forEach((element) => {
        element.cumple = this.form_seccion[index_seccion];
      });
      this.validaGestion();
    },
    //si
    validaGestion() {
      this.flagGestionado = true;
      this.form.forEach((value) => {
        value.forEach((value1) => {
          if (value1.cumple == null) {
            this.flagGestionado = false;
            return false;
          }
        });
      });
    },
    //adaptar
    async getData() {
      let det_inspeccion = [];
      let me = this;
      let estado_inspeccion = 2;
      let estado_vehiculo = 1;
      let no_relevante_incumplido = 0;
      let ahora = new Date();
      let fecha = `${ahora.getFullYear()}-${
        ahora.getMonth() + 1
      }-${ahora.getDate()}`;
      let hora = `${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`;

      this.form.forEach(function (value) {
        value.forEach(function (value) {
          det_inspeccion.push({
            id: value.id,
            tif_inspeccion_id: me.inspeccion_id,
            secciones_has_items_inspeccion_id:
              value.secciones_has_items_inspeccion_id,
            valor: value.valor,
            cumple: value.cumple,
          });
          // Se valida si se el item de inspección cumple con los requisitos
          if (value.relevante == 1 && !value.cumple) {
            estado_inspeccion = 4;
            estado_vehiculo = 2;
          }
          // Se valida si se el item de inspección cumple con los requisitos
          if (value.relevante == 0 && !value.cumple) {
            no_relevante_incumplido++;
          }
        });
      });

      // Se valida si hay inspecciones no relevantes no cumplidas
      if (estado_inspeccion == 2 && no_relevante_incumplido > 0) {
        estado_inspeccion = 5; // Estado Aprobado con novedades
      }

      this.forms.det_inspeccion = det_inspeccion;
      this.forms.inspeccion = {
        id: me.inspeccion_id,
        fecha_fin: fecha,
        hora_fin: hora,
        estado: estado_inspeccion,
        estado_documento: this.estadoForm.estado_documento,
      };

      this.forms.vehiculo = {
        id: me.vehiculo_id,
        estado: estado_vehiculo,
      };
    },
    //si
    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listaForm.estados_documento = response.data;
      });
    },
    //adaptar
    async save() {
      this.cargando = true;
      this.getData();
      let params = {
        det_inspeccion: this.forms.det_inspeccion,
      };
      await axios({
        method: "PUT",
        url: "/api/tif/inspecciones/gestionarInspeccion",
        data: params,
      })
        .then((response) => {
          // Se valida si se da por terminada la inspección
          this.$swal({
            icon: "success",
            title: "La inspección se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.getIndex();
          this.cargando = false;
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });

      if (this.forms.inspeccion.estado_documento == 2) {
        await this.terminarInspeccion();
      }
    },
    //adaptar
    terminarInspeccion() {
      if (this.forms.inspeccion.estado_documento == 2) {
        this.$swal({
          title: "Está seguro de terminar esta Inspección?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Terminar!",
        }).then((result) => {
          if (result.value) {
            this.cargando = true;
            if (this.forms.inspeccion.estado == 4) {
                this.forms.autorizacion = {
                  inspeccion_id: this.$parent.inspeccion_id,
                  usuario_id: this.$parent.form.responsable_id,
                  justificacion: this.$parent.form.observaciones,
                  datos_antiguos: {
                    nombre: this.inspeccion.nombre,
                    fecha: this.$parent.form.fecha_inicio,
                    placa: this.$parent.form.cabezote.placa,
                    det_postulacion: this.$parent.form.det_postulacion_id,
                  },
                };
              
            }
            let params = {
              inspeccion: this.forms.inspeccion,
              vehiculo: this.forms.vehiculo,
              autorizacion: this.forms.autorizacion,
            };
            axios({
              method: "PUT",
              url: "/api/tif/inspecciones/terminarInspeccion",
              data: params,
            })
              .then((response) => {
                this.getIndex();
                this.$swal({
                  icon: "success",
                  title: "Se actualizó el estado de la inspección con éxito...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.estadoForm.estado_documento = 2;
                this.cargando = false;
              })
              .catch((e) => {
                this.cargando = false;
                this.estadoForm.estado_documento = 1;
                this.$swal({
                  icon: "error",
                  title: "Ocurrió un error, vuelva a intentarlo..." + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          } else {
            this.estadoForm.estado_documento = 1;
          }
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
  },
};
</script>
